import React, { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

const CopyButton = ({ row }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(JSON.stringify(row));
        setCopied(true);

        // Reset the copied state after a short delay
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <Tooltip
            title={copied ? 'Copied!' : ''}
            placement="top"
            arrow
            enterDelay={200}
            leaveDelay={500}
        >
            <IconButton
                onClick={handleCopy}
                color="primary"
                size='small'
            >
                {copied ? <CheckIcon color="success" /> : <ContentCopyIcon fontSize="small" />}
            </IconButton>
        </Tooltip>
    );
};

export default CopyButton