import * as React from 'react';
import DataTable from '../DataTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function TenantList({ }) {

    return (
        <React.Fragment>
            <DataTable
                columns={[
                    {
                        field: 'id',
                        headerName: 'ID',
                        flex: 1
                    },
                    {
                        field: 'name',
                        headerName: 'Tenant Name',
                        flex: 1
                    },
                    {
                        field: 'email',
                        headerName: 'Email',
                        flex: 1
                    },
                    {
                        field: 'mobile',
                        headerName: 'Mobile',
                        flex: 1
                    },
                    {
                        field: 'contactPerson',
                        headerName: 'Contact Person',
                        flex: 1
                    },
                    {
                        field: 'actions',
                        type: 'actions',
                        headerName: 'Actions',
                        width: 100,
                        cellClassName: 'actions',
                        getActions: ({ id }) => {
                            return [
                                <GridActionsCellItem
                                    icon={<EditIcon />}
                                    label="Edit"
                                    className="textPrimary"
                                    onClick={() => {

                                    }}
                                    color="inherit"
                                />,
                                <GridActionsCellItem
                                    icon={<DeleteIcon />}
                                    label="Delete"
                                    onClick={() => {

                                    }}
                                    color="inherit"
                                />,
                            ];
                        },
                    }
                ]}

                rows={[
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 1, name: 'Name 1', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' },
                    { id: 2, name: 'Name 2', email: 'Snow', mobile: 'Jon', contactPerson: 'ABC' }
                ]}
                
            />
        </React.Fragment>
    );
}