import axios from 'axios';
import { accountLinkingConstants } from '../constants';
import { userService } from './user.service';
const qs = require('qs');

let accept = "";

const sanitizeParams = (params) => {
    return params;
  }

const getHeaders = (headerObj = {}) => {
    //console.log('headerobj===>', headerObj, accept);

    let headers = {
        Accept: accept,
    };

    if (!headerObj['Content-Type'] && !headerObj['multipart/form-data']) {
        headers['Content-Type'] = 'application/json';
    }

    delete headerObj['multipart/form-data'];

    headers = {
        ...headers,
        ...headerObj
    }
    //headers['Authorization'] = `Bearer ${accessToken}`;
    return headers;
}

const getDefaultOptions = (options) => {
    return options;
}

const makeRequest = async (path, key = "api_call", reqInit, options = {}) => {
    const headers = getHeaders(options?.headers);
    const option = getDefaultOptions(options);
    const init = Object.assign({}, reqInit, { headers });

    return await axios({
        url: path,
        ...init,
        timeout: 30000,
    })
        .then(res => {
            return res;
        })
        .catch((err) => {

            if(key == accountLinkingConstants.API_KEY) {
                return err?.response;
            }
            
            if (err?.response?.status === 401) {
                userService.logout();
            } else if (err && !err.response) {
                const er = {
                    response: {
                        error: err,
                        status: 503
                    }
                }
                err = er;
            } else {
                err.response["error"] = err?.response?.data;
                delete err?.response?.data;
            }

            //console.log('Errorr on request 2 ===>', err, err?.response);
            return err?.response;
        });
}

const getParams = (queryParams = {}) => {
    return queryParams;
}

const get = async (path, key, queryParams, body = {}, options = {}) => {
    const getData = {
        method: 'GET',
        params: getParams(queryParams),
        paramsSerializer: (params) => {
            return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
        },
        data: { ...body }
    };
    return await makeRequest(path, key, getData, options)
}

const post = async (path, key, body, options = {}) => {
    const postData = {
        method: 'POST',
        data: { ...body },
        ...options
    };

    return await makeRequest(path, key, postData, options);
}

export const API = {
    post,
    get
}