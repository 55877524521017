import React, { useState } from 'react';
import { Menu, MenuItem, Button, FormControl, InputLabel, Select, TextField, IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterComponent from './FilterComponent';
import Box from '@mui/material/Box';

export default function MultiFilter({ columns, onFiltersChange }) {
    const [anchorEl, setAnchorEl] = useState(null);
    // const [logicOperator, setLogicOperator] = useState('AND');
    // const [filters, setFilters] = useState([{ column: '', operator: 'contains', value: '' }]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handleAddFilter = () => {
    //     setFilters([...filters, { column: '', operator: 'contains', value: '' }]);
    // };

    // const handleRemoveFilter = (index) => {
    //     setFilters(filters.filter((_, i) => i !== index));
    // };

    // const handleFilterChange = (index, field, value) => {
    //     const newFilters = [...filters];
    //     newFilters[index][field] = value;
    //     setFilters(newFilters);
    // };

    const handleApply = (filters, logicOperator) => {
        handleClose();
        onFiltersChange(filters, logicOperator);
    };

    const open = Boolean(anchorEl);

    return (
        <Box>
            <Button variant="outlined"
                startIcon={<FilterListIcon />}
                aria-controls={open ? 'filter-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}>
                Filters
            </Button>
            <Menu
                id="filter-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '600px',
                        padding: '10px'
                    },
                }}
            >
                <FilterComponent columns={columns} onApplyFilters={handleApply}></FilterComponent>
            </Menu>
        </Box>
    );
};
