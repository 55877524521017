import { combineReducers } from "redux";
import {authentication} from "./authentication.reducer";
import {tenantReducer} from "./tenant.reducer";
import { appReducer } from "./app.reducer";
import { systemLogsReducer } from "./systemlogs.reducer";
import { tenantLogsReducer } from "./tenantlogs.reducer";

export default combineReducers({
    app: appReducer,
    authentication,
    tenantReducer,
    systemLogsReducer,
    tenantLogsReducer
})