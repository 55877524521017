import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PanasonicLogo from './../../assets/logo/syslogin_panasonic_logo.png'
import LeftNav from './../LeftNav'
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Badge from '@mui/material/Badge';

import { appActions } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import UserAccountMenu from '../UserAccountMenu';

export default function TopBar({ }) {

    const dispatch = useDispatch()
    const userAccountMenu = useSelector(state => state?.app?.userAccountMenu || false);

    const open = Boolean(userAccountMenu);
    const handleClick = (event) => {
        dispatch(appActions.userAccountMenu(event.currentTarget))
    };

    return (
        <React.Fragment>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton size="large" aria-label="show 4 new mails" color="black">
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="black"
                >
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={""}
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="black"
                >
                    <AccountCircle />
                </IconButton>
            </Box>
            <UserAccountMenu />
        </React.Fragment>
    );
}