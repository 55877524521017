import React from 'react';
import dayjs from 'dayjs';
import { TextField, FormControl, Button, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

const CustomPagination = ({ page, filteredRows, maxRowsPerPage, onPageChange }) => {

    const handlePrevious = () => {
        if (page > 0) {
            onPageChange(page - 1);
        }
    };

    const handleNext = () => {
        if (filteredRows == maxRowsPerPage){
            onPageChange(page + 1);
        }
    };

    return (
        <Box>
            <Button
                variant="contained"
                style={{ marginRight: '10px' }}
                onClick={handlePrevious}
                disabled={page === 0}
            >Previous</Button>

            <Button
                variant="contained"
                onClick={handleNext}
                //disabled={(page + 1) * pageSize > totalRows}
                disabled={filteredRows < maxRowsPerPage}
            >Next</Button>
        </Box>
    );
};

export default CustomPagination;