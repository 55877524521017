import React from 'react';

const addPropsToReactElement = (element, props) => {
    if (React.isValidElement(element)) {
    console.log("Use Props",React.cloneElement(element, props))
      return React.cloneElement(element, props)
    }
    return element
  }

const addPropsToChildren = (children, props) => {
    if (!Array.isArray(children)) {
      return addPropsToReactElement(children, props)
    }
    return children.map(childElement =>
      addPropsToReactElement(childElement, props)
    )
  }

export const useProps=addPropsToChildren;