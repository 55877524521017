import { handleError } from "./error.helper";

export const handleResponse = (response) => {
    return new Promise((resolve, reject) => {
        if (response) {
            if (response.status < 300 && response.status >= 200) {
                resolve(response?.data || {})
            } else {
                console.log('API error', response)
                reject(handleError(response))
            }
        }
    })
}