
import { systemLogService } from '../../services';
import { request, success, failure, getMessage } from '../../helpers';
import { systemLogsTypes, userTypes } from '../types';
import { appActions } from './app.actions';
import { setAuthToken } from '../../helpers/request.helper';

const getSystemLogs = (filterParams) => {

    return dispatch => {
        dispatch(request(systemLogsTypes.GET_SYSTEM_LOGS_REQUEST));

        systemLogService.getSystemLogs(filterParams)
            .then(
                data => {
                    if (data && data.hasOwnProperty('message')) {
                        dispatch(failure(systemLogsTypes.GET_SYSTEM_LOGS_FAILURE, data?.message.toString() ?? "Failed to Get the System Logs Data"));
                        getMessage(`sys-logs-no-data-found`).then((msg) => {
                            dispatch(appActions.apiErrorMessage(msg));
                        })
                        return
                    }
                    else {
                        dispatch(success(systemLogsTypes.GET_SYSTEM_LOGS_SUCCESS, { payload: data }));
                    }
                },
                ({ error, code }) => {
                    dispatch(failure(systemLogsTypes.GET_SYSTEM_LOGS_FAILURE, "Failed to Get the System Logs Data"));
                    getMessage(`sys-logs-api-failure`).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

export const systemLogsActions = {
    getSystemLogs
}