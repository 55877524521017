import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BackgroundImage from './../../assets/img/system_login_bg.png'
import PanasonicImage from './../../assets/logo/syslogin_panasonic_logo.png'
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, tenantActions } from './../../redux/actions'
import { PANASONIC_CLIENT_ID } from '../../config/appConfig';

//const defaultTheme = createTheme();

export default function SignIn() {

  const dispatch = useDispatch()

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(userActions.systemUserLogin(username, password));
  };

  useEffect(() => {
    //get tenant details
    tenantActions.getTenantInfo()
  }, [])

  return (
    //<ThemeProvider theme={defaultTheme}>
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        bgcolor='black'
      >
        {/* <Box
            component={'img'}
            src={MirAieLogo}
            sx={{
              width: '200px',
              position: 'relative',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
          </Box> */}
      </Grid>
      <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square flexDirection={'column'}>
        <Box
          sx={{
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '95vh'
          }}
        >
          <Box
            component={"img"}
            src={PanasonicImage}
            alt="Alternate Text"
            sx={{
              position: 'relative',
              width: '200px'
            }}
            mb='20px'
          ></Box>
          <Typography
            component="h1"
            variant="h5"
          >
            Login to your account
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              mt: 1,
              width: '400px'
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              value={username}
              onChange={(e) => {
                setUsername(e.target.value)
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, p: 1, backgroundColor: "#0056a8" }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Forgot password?"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          component="footer"
          sx={{
            py: 1,
            px: 1,
            mt: 'auto',
            textAlign: "center"
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="body1">
              <small>© 2024 - Panasonic Life Solutions India Pvt. Ltd. All rights reserved</small>
            </Typography>
          </Container>
        </Box>
      </Grid>
    </Grid>
    //</ThemeProvider>
  );
}