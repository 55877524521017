import './App.css';
import RootRouter from './routes/index.js'
import {BrowserRouter} from "react-router-dom"
import { Provider } from 'react-redux'
import store from './redux/store';

function App() {
    return (
        <div id="App">
            <Provider store={store}>
                <BrowserRouter>
                    <RootRouter></RootRouter>
                </BrowserRouter>
            </Provider>
        </div>
    )
}

export default App
