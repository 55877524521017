import React from 'react';
import dayjs from 'dayjs';
import { TextField, FormControl, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

const DateRangeFilter = ({ startDate, endDate, onDateChange, applyFilters }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <FormControl variant="outlined" style={{ marginRight: '10px' }} size='small'>
          <DatePicker
            value={dayjs(startDate.toISOString().split('T')[0])}
            onChange={(newDate) => onDateChange('startDate', newDate)}
            renderInput={(params) => <TextField {...params} size="small"/>}
            label="Start Date"
            maxDate={dayjs()}
          />
        </FormControl>

        <FormControl variant="outlined" style={{ marginRight: '10px' }} size='small'>
          <DatePicker
            value={dayjs(endDate.toISOString().split('T')[0])}
            onChange={(newDate) => onDateChange('endDate', newDate)}
            renderInput={(params) => <TextField {...params} size="small"/>}
            label="End Date"
            maxDate={dayjs()}
          />
        </FormControl>
        <Button variant="contained" size='small' onClick={applyFilters}>Apply Filter</Button>
      </div>
    </LocalizationProvider>
  );
};

export default DateRangeFilter;