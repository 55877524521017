
import { userService } from '../../services';
import { request, success, failure, getMessage } from '../../helpers';
import { authenticationTypes, userTypes } from '../types';
import { appActions } from './app.actions';
import { setAuthToken } from '../../helpers/request.helper';

const systemUserLogin = (username, password) => {

    return dispatch => {
        dispatch(request(authenticationTypes.LOGIN_REQUEST));

        userService.systemUserLogin(username, password)
            .then(
                user => {
                    dispatch(success(authenticationTypes.LOGIN_SUCCESS, user));
                    setAuthToken(user.accessToken) // set authentication token in axios
                },
                ({error, code}) => {
                    dispatch(failure(authenticationTypes.LOGIN_FAILURE, error?.toString() ?? "Login Error"));
                    //dispatch(appActions.apiErrorMessage(getMessage("login-post-400")));
                    //dispatch(appActions.apiErrorMessage("Error"));
                    getMessage(`login-post-${code}`, 
                        {remainingAttempts: error?.remainingAttempts ?? null, message: error?.message ?? ""}).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const systemUserLogout = () => {

    return dispatch => {
        //dispatch(request(authenticationTypes.LOGIN_REQUEST));
        userService.systemUserLogout()
            .then(
                res => {
                    dispatch(success(authenticationTypes.LOGOUT));

                    if (window && window.location && window.location.reload) {
                        window.location.reload();
                    } else if (document && document.location && document.location.reload) {
                        document.location.reload();
                    }
                },
                error => {
                    dispatch(success(authenticationTypes.LOGOUT));

                    if (window && window.location && window.location.reload) {
                        window.location.reload();
                    } else if (document && document.location && document.location.reload) {
                        document.location.reload();
                    }
                }
            );
    };
}

const getAccountLinkingAuthToken = (username, password, clientId, responseType, redirectURL, cb = () => { }) => {
    return dispatch => {
        userService.getAccountLinkingAuthToken(username, password, clientId, responseType, redirectURL)
            .then(
                (data) => {
                    let authorizationToken = data.authorizationToken
                    let clientType = data.clientType
                    cb(authorizationToken, clientType)
                },
                ({error, code}) => {
                    dispatch(failure(userTypes.GET_ACCOUNT_LINKING_AUTH_TOKEN, error?.toString() ?? ""));
                    getMessage(`auth-token-${code}`, 
                        {remainingAttempts: error?.remainingAttempts ?? null, message: error?.message ?? ""}).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const getAccountLinkingAuthCode = (scope, accepted, authorizationToken, cb = () => { }) => {
    return dispatch => {
        userService.getAccountLinkingAuthCode(scope, accepted, authorizationToken)
            .then(
                res => {
                    let authorizationCode = res.authorizationCode
                    cb(authorizationCode)
                },
                ({ error, code }) => {
                    dispatch(failure(userTypes.GET_ACCOUNT_LINKING_AUTH_CODE, error?.message?.toString() ?? ""));
                    getMessage(`auth-code-${code}`).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

export const userActions = {
    systemUserLogin,
    systemUserLogout,
    getAccountLinkingAuthToken,
    getAccountLinkingAuthCode
}