import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField, MenuItem, Select, FormControl, InputLabel, IconButton } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

const initialFilters = [
  { columnField: '', operatorValue: 'equals', value: '' },
];

const FilterComponent = ({ columns, onApplyFilters }) => {
  const [filters, setFilters] = useState(initialFilters);
  const [logicOperator, setLogicOperator] = useState('AND');

  const handleAddFilter = () => {
    setFilters([...filters, { columnField: '', operatorValue: 'contains', value: '' }]);
  };

  const handleRemoveFilter = (index) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const handleFilterChange = (index, field, value) => {
    const newFilters = [...filters];
    newFilters[index][field] = value;
    setFilters(newFilters);
  };

  const handleApplyFilters = () => {
    onApplyFilters(filters, logicOperator);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <FormControl variant="outlined" style={{ minWidth: 120, marginBottom: '10px' }} size="small">
        <InputLabel>Logic Operator</InputLabel>
        <Select
          value={logicOperator}
          onChange={(e) => setLogicOperator(e.target.value)}
          label="Logic Operator"
        >
          <MenuItem value="AND">AND</MenuItem>
          {/* <MenuItem value="OR">OR</MenuItem> */}
        </Select>
      </FormControl>

      {filters.map((filter, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <FormControl variant="outlined" style={{ minWidth: 120, marginRight: '10px' }} size="small">
            <InputLabel>Column</InputLabel>
            <Select
              value={filter.columnField}
              onChange={(e) => handleFilterChange(index, 'columnField', e.target.value)}
              label="Column"
            >
              {columns.map((column) => (
                <MenuItem key={column.field} value={column.field}>
                  {column.headerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" style={{ minWidth: 120, marginRight: '10px' }} size="small">
            <InputLabel>Operator</InputLabel>
            <Select
              value={filter.operatorValue}
              onChange={(e) => handleFilterChange(index, 'operatorValue', e.target.value)}
              label="Operator"
            >
              {/* <MenuItem value="contains">Contains</MenuItem> */}
              <MenuItem value="equals">Equals</MenuItem>
              {/* <MenuItem value="startsWith">Starts With</MenuItem>
              <MenuItem value="endsWith">Ends With</MenuItem> */}
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            label="Value"
            value={filter.value}
            onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
            style={{ marginRight: '10px', minWidth:150 }}
            size="small"
          />

          <IconButton onClick={() => handleRemoveFilter(index)}>
            <RemoveCircleOutline />
          </IconButton>
        </div>
      ))}

      <Button variant="outlined" onClick={handleAddFilter} startIcon={<AddCircleOutline />}>
        Add Filter
      </Button>

      <Button variant="contained" color="primary" onClick={handleApplyFilters} style={{ marginLeft: '10px' }}>
        Apply Filters
      </Button>
    </div>
  );
};

export default FilterComponent;
