export const userMessages = (item) => {
    return {
        // login
        "login-post-200": "Login success.",
        "login-post-403": `${item?.message?.toLowerCase()?.includes("expired") ? "Password has expired, please reset the password and try again." : item?.remainingAttempts === 0 ? "Account is locked due to multiple wrong attempt of the password, please reset the password and try again." : "Unable to login due to invalid credentials, please try again with correct credentials. You have " + item?.remainingAttempts + " attempt(s) left."}`,
        "login-post-400": `${item?.remainingAttempts !== null ? (item?.remainingAttempts === 0 ? "Account is locked due to multiple wrong attempt of the password, please reset the password and try again." : "Unable to login due to invalid credentials, please try again with correct credentials. You have " + item?.remainingAttempts + " attempt(s) left.") : "Unable to login due to invalid credentials, please try again with correct credentials."}`,
        "login-post-401": `${item?.remainingAttempts !== null ? (item?.message?.toLowerCase()?.includes("expired") ? "Password has expired, please reset the password and try again." : item?.remainingAttempts === 0 || item?.message?.toLowerCase()?.includes("locked") ? "Account is locked due to multiple wrong attempt of the password, please try again after sometime." : "Unable to login due to invalid credentials, please try again with correct credentials.") : "Unable to login due to invalid credentials, please try again with correct credentials."}`,
        "login-post-404": `${item?.remainingAttempts !== null ? (item?.remainingAttempts === 0 ? "Account is locked due to multiple wrong attempt of the password, please reset the password and try again." : "Unable to login due to invalid credentials, please try again with correct credentials. You have " + item?.remainingAttempts + " attempt(s) left.") : "Unable to login due to invalid credentials, please try again with correct credentials."}`,
        "login-post-500": "Service is temporarily unavailable due to internal error. Please try again later.",
        "login-post-501": "Service is temporarily unavailable. Please try again later.",
        "login-post-502": "Service is temporarily unavailable. Please try again later.",
        "login-post-503": "Service is temporarily unavailable. Please try again later.",

        // account linking messages
        "auth-token-401": `${item?.remainingAttempts !== null ? (item?.message?.toLowerCase()?.includes("expired") ? "Password has expired, please reset the password and try again." : item?.remainingAttempts === 0 ? "Account is locked due to multiple wrong attempt of the password, please reset the password and try again." : "Unable to login due to invalid credentials, please try again with correct credentials. You have " + item?.remainingAttempts + " attempt(s) left.") : "Unable to login due to invalid credentials, please try again with correct credentials."}`,
        "auth-token-403": `${item?.message?.toLowerCase()?.includes("locked") ? "Account is locked due to multiple wrong attempt of the password, please try after sometime." : item?.message}`,
        "auth-token-500": "Service is temporarily unavailable due to internal error. Please try again later.",
        "auth-token-501": "Service is temporarily unavailable due to internal error. Please try again later.",
        "auth-token-502": "Service is temporarily unavailable due to internal error. Please try again later.",
        "auth-token-503": "Service is temporarily unavailable due to internal error. Please try again later.",
        "auth-code-403": `${item?.message?.toLowerCase()?.includes("expired") ? "Authorization Token is expired. Please try again later.": "Invalid Authorization Token. Please try again later."}`,
        "auth-code-500": "Service is temporarily unavailable due to internal error. Please try again later.",
        "auth-code-500": "Service is temporarily unavailable due to internal error. Please try again later.",
        "auth-code-501": "Service is temporarily unavailable due to internal error. Please try again later.",
        "auth-code-502": "Service is temporarily unavailable due to internal error. Please try again later.",
        "auth-code-503": "Service is temporarily unavailable due to internal error. Please try again later.",
    }
}