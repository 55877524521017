export const authenticationTypes = {
    LOGIN_USER: "login_user",
    LOGOUT_USER: "logout_user",
    CREATE_USER_PROFILE: "create_user_profile",

    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    LOGOUT: 'USER_LOGOUT'
}