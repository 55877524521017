import { API } from '.'
import { handleError, handleResponse } from '../helpers';
import { API_URL, clientId } from '../config/appConfig';

const getSystemLogs = async (filterparams) => {
    return API.get(`${API_URL.auth}/system/events`, "system_logs", filterparams)
        .then(handleResponse)
}

export const systemLogService = {
    getSystemLogs
};