import { tenantTypes } from "../types";
import { defaultPanasonicTenantDetails } from "../../utils/deafultTenant";
const initialState = {
  tenantInfo: defaultPanasonicTenantDetails,
  tenantError: false,
};

export function tenantReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case tenantTypes.GET_TENANT_INFO:
      return {
        ...state,
        tenantInfo: payload,
        tenantError: false,
      };
    case tenantTypes.TENANT_INFO_ERROR:
      return {
        ...state,
        tenantInfo: initialState.tenantInfo,
        tenantError: true,
      };
    default:
      return state;
  }
}
