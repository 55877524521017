import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField, MenuItem, Select, FormControl, InputLabel, Grid } from '@mui/material';
import { systemLogsActions } from '../../redux/actions';
import dayjs from 'dayjs'
import DataTable from '../../components/DataTable';
import DateRangeFilter from '../../components/DateRangeFilter';
import CustomPagination from '../../components/CustomPagination';
import MultiFilter from '../../components/MultiFilter';
import { getMessage } from '../../helpers';
import { appActions } from '../../redux/actions';
import CopyButton from '../../components/CopyButton';


// set the default page size
const defaultPageSize = 30

// set the default last 7 days data
let defaultLast7DaysDate = new Date()
defaultLast7DaysDate.setDate(defaultLast7DaysDate.getDate() - 7)

// define cloumn definitions
const columns = [
  { field: 'id', headerName: 'ID' },
  { field: 'type', headerName: 'Event Type', flex: 1 },
  { field: 'actorUserId', headerName: 'Actor User ID', flex: 1 },
  { field: 'subjectId', headerName: 'Subject ID', flex: 1 },
  { field: 'subjectName', headerName: 'Subject Name', flex: 1 },
  {
    field: 'payload', headerName: 'Payload', flex: 1, valueFormatter: (params) => {
      // Check if the value is a JSON object
      if (typeof params.value === 'object') {
        return JSON.stringify(params.value);
      }
      return params.value;
    },
  },
  { field: 'status', headerName: 'Status', flex: 1 },
  {
    field: 'ts', headerName: 'Timestamp', flex: 1, valueFormatter: (params) => {
      if (typeof params.value === 'number') {
        return dayjs(params.value).format('YYYY-MM-DD');
      }
      return params.value;
    },
  },
  {
    field: 'actions', headerName: 'Actions', renderCell: (params) => (
      <CopyButton row={params.row} />
    ),
  },
];


const SystemLogs = () => {

  const dispatch = useDispatch()
  const [logicOperator, setLogicOperator] = useState('AND');
  const filteredRows = useSelector(state => state?.systemLogsReducer?.systemLogs)
  const [startDate, setStartDate] = useState(defaultLast7DaysDate);
  const [endDate, setEndDate] = useState(new Date());
  const [filters, setFilters] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);

  useEffect(() => {
    applyDateFilters() // be default get the data for default start & end date
  }, [])

  const applyColumnFilters = (filters, logicOperator) => {
    let isDateFilterValid = handleDateFilterValidation()
    if (!isDateFilterValid) {
      return
    }

    let filterObject = {}
    if (filters && filters.length > 0) {
      filters.forEach(filter => {
        filterObject[filter["columnField"]] = filter["value"]
      })

      setFilters(filterObject)

      setPageOffset(0) // when column filter chnages, then fetch fresh data from starting

      dispatch(systemLogsActions.getSystemLogs({
        startTs: parseInt(new Date(startDate).getTime() / 1000),
        endTs: parseInt(new Date(endDate).getTime() / 1000),
        index: 0,
        count: defaultPageSize,
        ...filterObject
      }))
    }
    else {
      setFilters({}) // remove the filters

      setPageOffset(0) // when column filter chnages, then fetch fresh data from starting

      dispatch(systemLogsActions.getSystemLogs({
        startTs: parseInt(new Date(startDate).getTime() / 1000),
        endTs: parseInt(new Date(endDate).getTime() / 1000),
        index: 0,
        count: defaultPageSize
      }))
    }
  };

  const applyDateFilters = () => {
    let isDateFilterValid = handleDateFilterValidation()
    if (!isDateFilterValid) {
      return
    }

    setPageOffset(0) // when date filter chnages, then fetch fresh data from starting

    dispatch(systemLogsActions.getSystemLogs({
      startTs: parseInt(new Date(startDate).getTime() / 1000),
      endTs: parseInt(new Date(endDate).getTime() / 1000),
      index: 0,
      count: defaultPageSize,
      ...filters
    }))
  }

  const handlePageChange = (newPage) => {
    setPageOffset(newPage);

    dispatch(systemLogsActions.getSystemLogs({
      startTs: parseInt(new Date(startDate).getTime() / 1000),
      endTs: parseInt(new Date(endDate).getTime() / 1000),
      index: newPage,
      count: defaultPageSize,
      ...filters
    }))
  };

  const handleDateFilterValidation = () => {
    if (startDate == null || endDate == null) {

    }
    else if (startDate > endDate) {
      getMessage(`sys-logs-start-date-greater-than-end-date`).then((msg) => {
        dispatch(appActions.apiErrorMessage(msg));
      })
      return false
    }
    return true
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container style={{ marginTop: '5px' }}>
        <Grid item xs={6}>
          <MultiFilter
            columns={columns}
            onFiltersChange={(filters, logicOperator) => {
              setFilters(filters);
              setLogicOperator(logicOperator);
              applyColumnFilters(filters, logicOperator);
            }}>
          </MultiFilter>
        </Grid>
        <Grid item xs={6}>
          <DateRangeFilter
            startDate={startDate}
            endDate={endDate}
            onDateChange={(field, value) => {
              if (field === 'startDate') {
                setStartDate(value);
              } else if (field === 'endDate') {
                setEndDate(value);
              }
            }}
            applyFilters={applyDateFilters}
          >
          </DateRangeFilter>
        </Grid>
      </Grid>
      <DataTable
        rows={filteredRows && filteredRows.map((row, index) => ({
          ...row,
          id: index + 1,
        }))}
        columns={columns}
        pageSize={defaultPageSize}
        showPagination={false}
        columnVisibilityModel={{
          id: false
        }}
        styling={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#eeeeee', // Set your desired header background color
            color: 'black', // Set the text color
            fontWeight: 'bold', // Make the headers bold
          },
        }}
      >
      </DataTable>
      <div style={{ justifyContent: 'flex-end', display: 'flex', marginTop: '10px' }}>
        <CustomPagination
          page={pageOffset}
          pageSize={defaultPageSize}
          filteredRows={filteredRows.length}
          maxRowsPerPage={defaultPageSize}
          onPageChange={handlePageChange}
        ></CustomPagination>
      </div>
    </Box>
  );
}

export default SystemLogs;