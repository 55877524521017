import PanasonicImage from './../assets/logo/logo_panasonic.jpeg'
import FlipkartImage from './../assets/logo/logo_smarthomeplus.png'
import AmazonImage from './../assets/logo/logo_appliancehub.png'

export const defaultPanasonicTenantDetails = {
    tenantId: "panasonic",
    tenantLogoUrl: PanasonicImage,
    primaryColor: "#3c3c3c",
    secondaryColor: "#000000",
    app: {
        baseUrl: process.env.REACT_APP_API_URL
    },
    auth: {
        baseUrl: process.env.REACT_APP_AUTH_URL,
        webClientId: "66307010ed750bd75b804feb"
    }
}

export const defaultFlipkartTenantDetails = {
    tenantId: "flipkart",
    tenantLogoUrl: FlipkartImage,
    primaryColor: "#1976d2",
    secondaryColor: "#1976d2",
    app: {
        baseUrl: process.env.REACT_APP_API_URL
    },
    auth: {
        baseUrl: process.env.REACT_APP_AUTH_URL,
        webClientId: "6684e2a37892184e434318fa"
    }
}

export const defaultAmazonTenantDetails = {
    tenantId: "amazon",
    tenantLogoUrl: AmazonImage,
    primaryColor: "#3c3c3c",
    secondaryColor: "#000000",
    app: {
        baseUrl: process.env.REACT_APP_API_URL
    },
    auth: {
        baseUrl: process.env.REACT_APP_AUTH_URL,
        webClientId: "TMwxjpY0LKMA0ydsgx1X2xKYJ4Ea"
    }
}