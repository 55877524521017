import { tenantTypes } from "../types";
import { TENANT_AMAZON_URI, TENANT_FLIPKART_URI, TENANT_PANASONIC_URI } from "../../utils/constants";
import { defaultPanasonicTenantDetails, defaultFlipkartTenantDetails, defaultAmazonTenantDetails } from "../../utils/deafultTenant";
import {setTenantId} from "./../../utils/requestHeaders"

const getTenantInfo = () => {

    return dispatch => {
        try {
            const host = window.location.host;
            let hostArr = host.split(".");

            let data = {}

            if (hostArr[0] === TENANT_PANASONIC_URI) {
                data = defaultPanasonicTenantDetails
            } else if (hostArr[0] === TENANT_FLIPKART_URI) {
                data = defaultFlipkartTenantDetails
            } else if (hostArr[0] === TENANT_AMAZON_URI) {
                data = defaultAmazonTenantDetails
            } else {
                // there is no other tenant
                data = defaultPanasonicTenantDetails
            }

            dispatch({
                type: tenantTypes.GET_TENANT_INFO,
                payload: data,
            });

            setTenantId(data.tenantId)
            
        } catch (error) {
            // write the code to set the default theme
            // temproary data
            let data = defaultPanasonicTenantDetails

            dispatch({
                type: tenantTypes.GET_TENANT_INFO,
                payload: data,
            });
        }
    }
}

export const tenantActions = {
    getTenantInfo
};