import { systemLogsTypes } from "../types";
import { defaultPanasonicTenantDetails } from "../../utils/deafultTenant";
const initialState = {
    systemLogs: [],
    systemLogsLoading: false,
    systemLogsError: false,
};

export function systemLogsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case systemLogsTypes.GET_SYSTEM_LOGS_REQUEST:
            return {
                ...state,
                systemLogs: [],
                systemLogsLoading: true,
                systemLogsError: false,
            };
        case systemLogsTypes.GET_SYSTEM_LOGS_SUCCESS:
            return {
                ...state,
                systemLogs: payload,
                systemLogsLoading: false,
                systemLogsError: false,
            };
        case systemLogsTypes.GET_SYSTEM_LOGS_FAILURE:
            return {
                ...state,
                systemLogs: [],
                systemLogsLoading: false,
                systemLogsError: true,
            }
        default:
            return state;
    }
}
