import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsIcon from '@mui/icons-material/Settings';
//import SystemLogs from '../pages/SystemLogs';
import TenantManagement from '../pages/TenantManagement';
import SystemLogs from '../pages/SystemLogs';
import TenantLogs from '../pages/TenantLogs';

const routes = [
    {
        id: 1001,
        parent: null,
        icon: '',
        isPublicRoute: true,
        path: '',
        name: 'login',
        label: "Login",
        tooltip: "Login",
        component: LoginPage,
        exact: true,
        topMenu: false,
        subMenu: false
    },
    {
        id: 1021,
        icon: <SettingsIcon></SettingsIcon>,
        isPublicRoute: false,
        path: 'dashboard',
        name: 'dashboard',
        label: "Dashboard",
        tooltip: "Dashboard",
        component: HomePage,
        exact: true,
        topMenu: false,
        leftMenu: true,
        subMenu: false
    },
    // {
    //     id: 1031,
    //     icon: <SettingsIcon></SettingsIcon>,
    //     isPublicRoute: false,
    //     path: 'systemconfig',
    //     name: 'System Configuration',
    //     label: "System Configuration",
    //     tooltip: "System Configuration",
    //     component: HomePage,
    //     exact: true,
    //     topMenu: false,
    //     leftMenu: true,
    //     subMenu: false
    // },
    // {
    //     id: 1041,
    //     icon: <SettingsIcon></SettingsIcon>,
    //     isPublicRoute: false,
    //     path: 'tenantmgmt',
    //     name: 'Tenant Management',
    //     label: "Tenant Management",
    //     tooltip: "Tenant Management",
    //     component: TenantManagement,
    //     exact: true,
    //     topMenu: false,
    //     leftMenu: true,
    //     subMenu: false
    // },
    // {
    //     id: 1051,
    //     icon: <SettingsIcon></SettingsIcon>,
    //     isPublicRoute: false,
    //     path: 'clientconfig',
    //     name: 'Client Configuration',
    //     label: "Client Configuration",
    //     tooltip: "Client Configuration",
    //     component: HomePage,
    //     exact: true,
    //     topMenu: false,
    //     leftMenu: true,
    //     subMenu: false
    // },
    {
        id: 1061,
        icon: <ManageSearchIcon/>,
        isPublicRoute: false,
        path: 'systemlogs',
        name: 'System Logs',
        label: "System Logs",
        tooltip: "System Logs",
        component: SystemLogs,
        exact: true,
        topMenu: false,
        leftMenu: true,
        subMenu: false
    },
    {
        id: 1071,
        icon: <ManageSearchIcon/>,
        isPublicRoute: false,
        path: 'tenantLogs',
        name: 'Tenant Logs',
        label: "Tenant Logs",
        tooltip: "Tenant Logs",
        component: TenantLogs,
        exact: true,
        topMenu: false,
        leftMenu: true,
        subMenu: false
    }
];

export default routes;