import { defaultSelectedMenu } from '../../config/appConfig';
import { appTypes } from '../types';

let initialState = { 
    selectedLeftMenu: defaultSelectedMenu.left,
    selectedTopMenu:  defaultSelectedMenu.top,
    loading: false,
    theme: "light",
    messageType: null,
    message: null,
    mqttRequests:[],
    userAccountMenu: null
}

export function appReducer(state = initialState, action) {
    switch (action.type) {
        case appTypes.SELECT_LEFT_MENU:
            return {
                ...state,
                selectedLeftMenu: action?.payload,
            };
        case appTypes.SELECT_TOP_MENU:
            return {
                ...state,
                selectedTopMenu: action.payload,
            };
        case appTypes.THEME:
            return {
                ...state,
                theme: action.payload,
            };
        case appTypes.API_SUCCESS:
            return {
                ...state,
                messageType: action.message ? 'success' : null,
                message: action.message
            };
        case appTypes.API_ERROR:
            return {
                ...state,
                messageType: action.message ? 'error' : null,
                message: action.message
            };
        case appTypes.API_CLEAR:
            return {
                ...state,
                loading: false,
                messageType: null,
                message: null
            };
        
        case appTypes.USER_ACCOUNT_MENU:
            return {
                ...state,
                userAccountMenu: action.payload
            }
        
        default:
            return state
    }
}