import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated }) => {

    const location = useLocation();

    return (
        !isAuthenticated ? <Navigate to="/" state={{ from: location }} replace /> : <Outlet/>
    );
}

export default PrivateRoute;