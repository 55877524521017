import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import SideBar from '../../components/Sidebar';
import TopBar from '../../components/TopBar';
import { useDispatch } from 'react-redux';
//import Footer from '../../components/Footer';
import { useProps } from '../../hooks';

const MainLayout = ({ children, ...props }) => {
    //const dispatch = useDispatch();
    const addProps = useProps;

    return (
        <React.Fragment>
            <TopBar />
            <SideBar>
                {addProps(children, { ...props })}
            </SideBar>
        </React.Fragment>
    )
};

MainLayout.propTypes = {};

MainLayout.defaultProps = {};

export default MainLayout;
