import { API } from '.'
import { handleError, handleResponse } from '../helpers';
import { API_URL } from '../config/appConfig';
import { accountLinkingConstants } from '../constants';

const systemUserLogin = async (username, password, clientId) => {
    return API.post(`${API_URL.idpAuth}/system/auth/login`, "login", { username, password})
        //.then(handleResponse)
        .then(res => {
            return handleResponse(res)
        })
}

const systemUserLogout = () => {
    return API.post(`${API_URL.idp}/system/auth/logout`, "logout", {})
        .then(handleResponse)
        .then(res => {
            return {}
        })
}

const getAccountLinkingAuthToken = (username, password, clientId, responseType, redirectURL) => {
    // add an API to get the account linking authorization token

    let reqBody = {
        password,
        clientId,
        responseType,
        "redirectUrl": redirectURL
    }

    if (username.includes('@')) {
        reqBody['email'] = username
    } else{
        reqBody['mobile'] = username
    }

    return API.post(`${API_URL.idpAuth}/oauth2/login`, accountLinkingConstants.API_KEY, reqBody)
        .then(res => {
            console.log('response from login', res)
            return handleResponse(res, true)
        })
}

const getAccountLinkingAuthCode = (scope, accepted, authorizationToken) => {
    // add an API to get the account linking access token using the auth code

    // add an API to get the account linking authorization token
    return API.post(`${API_URL.idpAuth}/oauth2/authcode`, accountLinkingConstants.API_KEY, {
        scope,
        accepted
    }, {
        'headers':{
            'Authorization': `Bearer ${authorizationToken}`
        }
    })
        .then(res => {
            console.log('response from auth code API', res)
            return handleResponse(res, true)
        })
}

export const userService = {
    systemUserLogin,
    systemUserLogout,
    getAccountLinkingAuthToken,
    getAccountLinkingAuthCode
};