import { tenantLogsTypes } from "../types";
import { defaultPanasonicTenantDetails } from "../../utils/deafultTenant";
const initialState = {
    tenantLogs: [],
    tenantLogsLoading: false,
    tenantLogsError: false,
};

export function tenantLogsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case tenantLogsTypes.GET_TENANT_LOGS_REQUEST:
            return {
                ...state,
                tenantLogs: [],
                tenantLogsLoading: true,
                tenantLogsError: false,
            };
        case tenantLogsTypes.GET_TENANT_LOGS_SUCCESS:
            return {
                ...state,
                tenantLogs: payload,
                tenantLogsLoading: false,
                tenantLogsError: false,
            };
        case tenantLogsTypes.GET_TENANT_LOGS_FAILURE:
            return {
                ...state,
                tenantLogs: [],
                tenantLogsLoading: false,
                tenantLogsError: true,
            }
        default:
            return state;
    }
}
