import React, { useEffect, useState } from 'react';
import { useStyles } from './StyledSnackBar.style.js';
import './StyledSnackBar.css';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { appActions } from '../../redux/actions/app.actions.js';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledSnackBar = ({ open = false, onClose, autoHideDuration, data, ...props }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [autoClose, setAutoClose] = useState(false);

    useEffect(() => {
        if (!open) {
            dispatch(appActions.apiClearMessage());
            setAutoClose(false);
        } else {
            setAutoClose(false);
            setTimeout(() => {
                dispatch(appActions.apiClearMessage());
                setAutoClose(true);
            }, autoHideDuration)
        }

    }, [dispatch, open, autoHideDuration])

    return (
        <React.Fragment>
            <Snackbar
                open={open && data && !autoClose ? open : false}
                onClose={onClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                className={classes?.StyledSnackBar || ""}
                autoHideDuration={autoHideDuration}
                severity={(data?.severity) || (data?.type === "success" ? "success" : (data?.type === "error" ? "error" : ""))}
                {...props}
            >
                <Alert
                    onClose={onClose}
                    severity={(data?.severity) || (data?.type === "success" ? "success" : (data?.type === "error" ? "error" : ""))}
                    sx={{ minWidth: "400px" }}
                >
                    {data.message || data.error || (data?.type === "success" ? "Success" : (data?.type === "error" ? "Error" : ""))}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
};

StyledSnackBar.propTypes = {};

StyledSnackBar.defaultProps = {};

export default StyledSnackBar;
