import React, { Suspense, lazy, Fragment, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { defaultPanasonicTenantDetails } from "../../utils/deafultTenant";
import { ThemeProvider, createTheme, hexToRgb } from '@mui/material/styles';
import ThemeColorContext from "./AccountThemeContext";
import { useSelector, useDispatch } from "react-redux";
import { tenantActions } from '../../redux/actions';

const Login = lazy(() => import('./AccountLogin'));
const Authorization = lazy(() => import('./AccountAuthorization'));

function Loading() {
    return <Fragment>
        <center>
            <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
        </center>
    </Fragment >
}

const AccountLinkingAccess = ({
    showLoginPage = true,
    showAuthorizationPage = false
}) => {

    const dispatch = useDispatch()

    const tenantInfo = useSelector(state => state?.tenantReducer?.tenantInfo || defaultPanasonicTenantDetails)
    const tenantPrimaryColor = useSelector((state => state?.tenantReducer?.tenantInfo?.primaryColor || defaultPanasonicTenantDetails.primaryColor))
    const [primaryColor, setPrimaryColor] = React.useState(tenantPrimaryColor)
    const themeColor = React.useMemo(
        () => ({
            changeThemeColor: (color) => {
                setPrimaryColor(color);
            },
        }),
        [],
    );

    useEffect(() => {
        //get tenant details
        dispatch(tenantActions.getTenantInfo())
    }, [])

    useEffect(()=>{
        setPrimaryColor(tenantPrimaryColor)
    }, [tenantPrimaryColor])

    useEffect(() => {
        //perform some validation here.. if passed then only show idp login page
        // this login page is invoked by Alexa and Google Home Only
    })

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: primaryColor
                    },
                    background: {
                        default: primaryColor
                    },
                    text: {
                        primary: tenantInfo.secondaryColor
                    }
                },
            }),
        [primaryColor, tenantInfo.secondaryColor],
    );

    return (
        <Suspense fallback={<Loading></Loading>}>
            <ThemeColorContext.Provider value={themeColor}>
                <ThemeProvider theme={theme}>
                    {
                        showLoginPage ? <Login></Login> : <Fragment></Fragment>
                    }
                    {
                        showAuthorizationPage ? <Authorization></Authorization> : <Fragment></Fragment>
                    }
                </ThemeProvider>
            </ThemeColorContext.Provider>
        </Suspense>
    )
}

export default AccountLinkingAccess