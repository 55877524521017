import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import _ from 'lodash';
import {
    DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar
} from '@mui/x-data-grid';
import axios from "axios";

export default function DataTable({
    rows,
    columns,
    pageSize,
    showPagination = true,
    columnVisibilityModel={},
    styling={}
}) {

    return (
        <React.Fragment>
            <DataGrid
                columnVisibilityModel={{
                    ...columnVisibilityModel
                }}
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                hideFooter
                sx={{ height: 'auto', ...styling }}
            />
        </React.Fragment>
    );
}