import { authenticationTypes } from '../types'

const initialState = {
    loggedIn: false,
    loading: false,
    user: {}
}

export function authentication(state = initialState, action) {
    switch (action.type) {
        case authenticationTypes.LOGIN_REQUEST: {
            return {
                loading: true,
                user: action.user
            };
        }
        case authenticationTypes.LOGIN_SUCCESS: {
            return {
                loggedIn: true,
                user: action.user,
                loading: false
            };
        }
        case authenticationTypes.LOGIN_FAILURE: {
            return {
                loading: false,
                error: action.payload,
                user:{}
            };
        }
        case authenticationTypes.LOGOUT: {
            return {
                loggedIn: false,
                loading: false,
                error: action.payload,
                user:{}
            }
        }
        default:
            return state
    }
}