import { useNavigate } from "react-router-dom"
import { connect, useDispatch } from "react-redux";
import { withRouter } from "../../utils/router"
import {useEffect, Fragment } from 'react'
import SideBar from "../../components/Sidebar";


const Home = ({ authReducer }) => {
  // const navigate = useNavigate()
  // const dispatch = useDispatch()

  return (
    <Fragment>
      {/* <SideBar></SideBar> */}
      <p>Home Page</p>
    </Fragment>
  )
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;

// const mapStateToProps = (state) => ({
//   //props made from redux store
//   //authReducer: state.authentication,
// });

// export default withRouter(
//   connect(mapStateToProps, {
//     //All functions defined in action will come here
//   })(Home)
// );