import { List } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import routes from '../../routes/routes';
import { defaultSelectedMenu } from '../../config/appConfig';
import { appTypes } from '../../redux/types';
import './LeftNav.css';
import { useNavigate } from 'react-router-dom';

const LeftNav = ({ open }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const selectedLeftMenu = useSelector(state => state?.app?.selectedLeftMenu || defaultSelectedMenu.left);

    const [sideMenus] = useState(() => {
        return routes?.filter(r => r.leftMenu && !r.isPublicRoute).map((sMenu, index) => {
            sMenu.leftComponent = sMenu.component;
            return sMenu;
        });
    });

    const handleMenuItemClick = (event, menuItem) => {
        console.log(event, menuItem)
        dispatch({ type: appTypes.SELECT_LEFT_MENU, payload: menuItem.id })

        navigate("/" + menuItem.path)
    }

    return (
        <List>
            {sideMenus.map((menuItem, index) => (
                <ListItem
                    key={menuItem.name}
                    disablePadding
                    sx={{
                        display: 'block'
                    }}
                    selected={menuItem.id == selectedLeftMenu}
                    onClick={(event) => {
                        handleMenuItemClick(event, menuItem)
                    }}
                    className={menuItem.id == selectedLeftMenu ? "active-menu" : ""}
                >
                    <ListItemButton
                        sx={{
                            minHeight: 62,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                            className={menuItem.id == selectedLeftMenu ? "active-icon" : ""}

                        >
                            {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                            {menuItem.icon}
                        </ListItemIcon>
                        <ListItemText primary={menuItem.label} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    )
};

LeftNav.propTypes = {};

LeftNav.defaultProps = {};

export default LeftNav;
