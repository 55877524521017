
import { appTypes } from "../types";

const selectLeftMenu = (selectedMenuId) => {
    return dispatch => {
        if (selectedMenuId) {
            dispatch({ type: appTypes.SELECT_LEFT_MENU, payload: selectedMenuId });
        }
    };
}

const userAccountMenu = (userAccountMenuVisiblity) => {
    return dispatch => {
        dispatch({ type: appTypes.USER_ACCOUNT_MENU, payload: userAccountMenuVisiblity });
    }
}

const apiErrorMessage = (message) => {
    return { type: appTypes.API_ERROR, message };
}

const apiSuccessMessage = (message) => {
    return { type: appTypes.API_SUCCESS, message };
}

const apiClearMessage = () => {
    return { type: appTypes.API_CLEAR };
}

export const appActions = {
    selectLeftMenu,
    apiErrorMessage,
    userAccountMenu,
    apiClearMessage,
    apiSuccessMessage
};