
import { systemLogService } from '../../services';
import { request, success, failure, getMessage } from '../../helpers';
import { ten, tenantLogsTypes, userTypes } from '../types';
import { appActions } from './app.actions';
import { setAuthToken } from '../../helpers/request.helper';
import { tenantLogService } from '../../services/tenantlogs.service';

const getTenantLogs = (filterParams) => {

    return dispatch => {
        dispatch(request(tenantLogsTypes.GET_TENANT_LOGS_REQUEST));

        tenantLogService.getTenantLogs(filterParams)
            .then(
                data => {
                    dispatch(success(tenantLogsTypes.GET_TENANT_LOGS_SUCCESS, {payload: data}));
                },
                ({error, code}) => {
                    dispatch(failure(tenantLogsTypes.GET_TENANT_LOGS_FAILURE, error?.toString() ?? "Error"));
                    // getMessage(`login-post-${code}`, 
                    //     {remainingAttempts: error?.remainingAttempts ?? null, message: error?.message ?? ""}).then((msg) => {
                    //     dispatch(appActions.apiErrorMessage(msg));
                    // })
                }
            );
    };
}

export const tenantLogsActions = {
    getTenantLogs
}