import { Box } from '@mui/system';
import React, { Fragment } from 'react'
import TenantList from '../../components/TenantList';

const TenantManagement = () => {

    return (
        <Fragment>
            <Box sx={{
                height: '100%',
                width: '100%',
                overflow: 'auto'
            }}>
                <TenantList />
            </Box>

        </Fragment>
    )
}

TenantManagement.propTypes = {};

TenantManagement.defaultProps = {};

export default TenantManagement;